import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Language = () => import('@/entities/language/language.vue');
const LanguageUpdate = () => import('@/entities/language/language-update.vue');
const LanguageDetails = () => import('@/entities/language/language-details.vue');

const ASREngine = () => import('@/entities/asr-engine/asr-engine.vue');
const ASREngineUpdate = () => import('@/entities/asr-engine/asr-engine-update.vue');
const ASREngineDetails = () => import('@/entities/asr-engine/asr-engine-details.vue');

const ASREngineConfiguration = () => import('@/entities/asr-engine-configuration/asr-engine-configuration.vue');
const ASREngineConfigurationUpdate = () => import('@/entities/asr-engine-configuration/asr-engine-configuration-update.vue');
const ASREngineConfigurationDetails = () => import('@/entities/asr-engine-configuration/asr-engine-configuration-details.vue');

const Sample = () => import('@/entities/sample/sample.vue');
const SampleUpdate = () => import('@/entities/sample/sample-update.vue');
const SampleDetails = () => import('@/entities/sample/sample-details.vue');

const MetadataFile = () => import('@/entities/metadata-file/metadata-file.vue');
const MetadataFileUpdate = () => import('@/entities/metadata-file/metadata-file-update.vue');
const MetadataFileDetails = () => import('@/entities/metadata-file/metadata-file-details.vue');

const TranscriptFile = () => import('@/entities/transcript-file/transcript-file.vue');
const TranscriptFileUpdate = () => import('@/entities/transcript-file/transcript-file-update.vue');
const TranscriptFileDetails = () => import('@/entities/transcript-file/transcript-file-details.vue');

const AudioFile = () => import('@/entities/audio-file/audio-file.vue');
const AudioFileUpdate = () => import('@/entities/audio-file/audio-file-update.vue');
const AudioFileDetails = () => import('@/entities/audio-file/audio-file-details.vue');

const AudioFormat = () => import('@/entities/audio-format/audio-format.vue');
const AudioFormatUpdate = () => import('@/entities/audio-format/audio-format-update.vue');
const AudioFormatDetails = () => import('@/entities/audio-format/audio-format-details.vue');

const MetaProperty = () => import('@/entities/meta-property/meta-property.vue');
const MetaPropertyUpdate = () => import('@/entities/meta-property/meta-property-update.vue');
const MetaPropertyDetails = () => import('@/entities/meta-property/meta-property-details.vue');

const Benchmark = () => import('@/entities/benchmark/benchmark.vue');
const BenchmarkUpdate = () => import('@/entities/benchmark/benchmark-update.vue');
const BenchmarkDetails = () => import('@/entities/benchmark/benchmark-details.vue');

const BenchmarkExecution = () => import('@/entities/benchmark-execution/benchmark-execution.vue');
const BenchmarkExecutionUpdate = () => import('@/entities/benchmark-execution/benchmark-execution-update.vue');
const BenchmarkExecutionDetails = () => import('@/entities/benchmark-execution/benchmark-execution-details.vue');

const BenchmarkResult = () => import('@/entities/benchmark-result/benchmark-result.vue');
const BenchmarkResultUpdate = () => import('@/entities/benchmark-result/benchmark-result-update.vue');
const BenchmarkResultDetails = () => import('@/entities/benchmark-result/benchmark-result-details.vue');

const EvaluationMetric = () => import('@/entities/evaluation-metric/evaluation-metric.vue');
const EvaluationMetricUpdate = () => import('@/entities/evaluation-metric/evaluation-metric-update.vue');
const EvaluationMetricDetails = () => import('@/entities/evaluation-metric/evaluation-metric-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/',
    component: Entities,
    children: [
      {
        path: 'language',
        name: 'Language',
        component: Language,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'language/new',
        name: 'LanguageCreate',
        component: LanguageUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'language/:languageId/edit',
        name: 'LanguageEdit',
        component: LanguageUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'language/:languageId/view',
        name: 'LanguageView',
        component: LanguageDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine',
        name: 'ASREngine',
        component: ASREngine,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine/new',
        name: 'ASREngineCreate',
        component: ASREngineUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine/:aSREngineId/edit',
        name: 'ASREngineEdit',
        component: ASREngineUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine/:aSREngineId/view',
        name: 'ASREngineView',
        component: ASREngineDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine-configuration',
        name: 'ASREngineConfiguration',
        component: ASREngineConfiguration,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine-configuration/new',
        name: 'ASREngineConfigurationCreate',
        component: ASREngineConfigurationUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine-configuration/:aSREngineConfigurationId/edit',
        name: 'ASREngineConfigurationEdit',
        component: ASREngineConfigurationUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'asr-engine-configuration/:aSREngineConfigurationId/view',
        name: 'ASREngineConfigurationView',
        component: ASREngineConfigurationDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'sample',
        name: 'Sample',
        component: Sample,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'sample/new',
        name: 'SampleCreate',
        component: SampleUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'sample/:sampleId/edit',
        name: 'SampleEdit',
        component: SampleUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'sample/:sampleId/view',
        name: 'SampleView',
        component: SampleDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'metadata-file',
        name: 'MetadataFile',
        component: MetadataFile,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'metadata-file/new',
        name: 'MetadataFileCreate',
        component: MetadataFileUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'metadata-file/:metadataFileId/edit',
        name: 'MetadataFileEdit',
        component: MetadataFileUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'metadata-file/:metadataFileId/view',
        name: 'MetadataFileView',
        component: MetadataFileDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'transcript-file',
        name: 'TranscriptFile',
        component: TranscriptFile,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'transcript-file/new',
        name: 'TranscriptFileCreate',
        component: TranscriptFileUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'transcript-file/:transcriptFileId/edit',
        name: 'TranscriptFileEdit',
        component: TranscriptFileUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'transcript-file/:transcriptFileId/view',
        name: 'TranscriptFileView',
        component: TranscriptFileDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-file',
        name: 'AudioFile',
        component: AudioFile,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-file/new',
        name: 'AudioFileCreate',
        component: AudioFileUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-file/:audioFileId/edit',
        name: 'AudioFileEdit',
        component: AudioFileUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-file/:audioFileId/view',
        name: 'AudioFileView',
        component: AudioFileDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-format',
        name: 'AudioFormat',
        component: AudioFormat,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-format/new',
        name: 'AudioFormatCreate',
        component: AudioFormatUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-format/:audioFormatId/edit',
        name: 'AudioFormatEdit',
        component: AudioFormatUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'audio-format/:audioFormatId/view',
        name: 'AudioFormatView',
        component: AudioFormatDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'meta-property',
        name: 'MetaProperty',
        component: MetaProperty,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'meta-property/new',
        name: 'MetaPropertyCreate',
        component: MetaPropertyUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'meta-property/:metaPropertyId/edit',
        name: 'MetaPropertyEdit',
        component: MetaPropertyUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'meta-property/:metaPropertyId/view',
        name: 'MetaPropertyView',
        component: MetaPropertyDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark',
        name: 'Benchmark',
        component: Benchmark,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark/new',
        name: 'BenchmarkCreate',
        component: BenchmarkUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark/:benchmarkId/edit',
        name: 'BenchmarkEdit',
        component: BenchmarkUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark/:benchmarkId/view',
        name: 'BenchmarkView',
        component: BenchmarkDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-execution',
        name: 'BenchmarkExecution',
        component: BenchmarkExecution,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-execution/new',
        name: 'BenchmarkExecutionCreate',
        component: BenchmarkExecutionUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-execution/:benchmarkExecutionId/edit',
        name: 'BenchmarkExecutionEdit',
        component: BenchmarkExecutionUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-execution/:benchmarkExecutionId/view',
        name: 'BenchmarkExecutionView',
        component: BenchmarkExecutionDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-result',
        name: 'BenchmarkResult',
        component: BenchmarkResult,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-result/new',
        name: 'BenchmarkResultCreate',
        component: BenchmarkResultUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-result/:benchmarkResultId/edit',
        name: 'BenchmarkResultEdit',
        component: BenchmarkResultUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'benchmark-result/:benchmarkResultId/view',
        name: 'BenchmarkResultView',
        component: BenchmarkResultDetails,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'evaluation-metric',
        name: 'EvaluationMetric',
        component: EvaluationMetric,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'evaluation-metric/new',
        name: 'EvaluationMetricCreate',
        component: EvaluationMetricUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'evaluation-metric/:evaluationMetricId/edit',
        name: 'EvaluationMetricEdit',
        component: EvaluationMetricUpdate,
        meta: { authorities: [Authority.USER] },
      },
      {
        path: 'evaluation-metric/:evaluationMetricId/view',
        name: 'EvaluationMetricView',
        component: EvaluationMetricDetails,
        meta: { authorities: [Authority.USER] },
      },
      // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
    ],
  },
];
