import { render, staticRenderFns } from "./jhi-navbar.vue?vue&type=template&id=2dff3d44&scoped=true&"
import script from "./jhi-navbar.component.ts?vue&type=script&lang=ts&"
export * from "./jhi-navbar.component.ts?vue&type=script&lang=ts&"
import style0 from "./jhi-navbar.vue?vue&type=style&index=0&id=2dff3d44&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dff3d44",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/bestt/bestt-platform/bestt-backend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2dff3d44')) {
      api.createRecord('2dff3d44', component.options)
    } else {
      api.reload('2dff3d44', component.options)
    }
    module.hot.accept("./jhi-navbar.vue?vue&type=template&id=2dff3d44&scoped=true&", function () {
      api.rerender('2dff3d44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app-ext/core/jhi-navbar/jhi-navbar.vue"
export default component.exports