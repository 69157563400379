import { Authority } from '@/shared/security/authority';

const BesttUserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const BesttUserManagementViewComponent = () => import('@/admin/user-management/user-management-view.vue');
const BesttUserManagementEditComponent = () => import('@/admin/user-management/user-management-edit.vue');
const BesttDocsComponent = () => import('@/admin/docs/docs.vue');
const BesttConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const BesttHealthComponent = () => import('@/admin/health/health.vue');
const BesttLogsComponent = () => import('@/admin/logs/logs.vue');
const BesttMetricsComponent = () => import('@/admin/metrics/metrics.vue');
const BesttTrackerComponent = () => import('@/admin/tracker/tracker.vue');

export default [
  {
    path: '/admin/user-management',
    name: 'BesttUser',
    component: BesttUserManagementComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/new',
    name: 'BesttUserCreate',
    component: BesttUserManagementEditComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/edit',
    name: 'BesttUserEdit',
    component: BesttUserManagementEditComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/view',
    name: 'BesttUserView',
    component: BesttUserManagementViewComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/docs',
    name: 'BesttDocsComponent',
    component: BesttDocsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/health',
    name: 'BesttHealthComponent',
    component: BesttHealthComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/logs',
    name: 'BesttLogsComponent',
    component: BesttLogsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/metrics',
    name: 'BesttMetricsComponent',
    component: BesttMetricsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/configuration',
    name: 'BesttConfigurationComponent',
    component: BesttConfigurationComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/tracker',
    name: 'BesttTrackerComponent',
    component: BesttTrackerComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
];
